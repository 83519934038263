.main {
    background-color: var(--bg-grey);
}

.content {
    max-width: 1360px;
    padding: 0 20px;
    margin: 0 auto 72px;
}

.text {
    display: flex;
    margin: 80px 0;
    gap: 40px;
}

.text h6 {
    color: var(--orange);
}

.about {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 2 1 0;
}

.about p {
    max-width: 500px;
}

.client {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1 1 0;
}

.images {
    width: 100%;
    overflow: hidden;
}

.exlink a {
    display: flex;
    align-items: center;
    gap: 8px;
}


@media(max-width: 1440px) {}

@media(max-width: 1360px) {}

@media(max-width: 1280px) {}

@media(max-width: 1024px) {}

@media(max-width: 768px) {
    .text {
        display: block;
    }
    .text .client {
        margin-top: 24px;
    }
}

@media(max-width: 560px) {}

@media(max-width: 480px) {}

@media(max-width: 360px) {}

@media(max-width: 320px) {}