.contents {
    width: 100%;
}

/* ––––––––––––––––––––––––––– GRID PROJECT –––––––––––––––––––– */


.img {
    width: 100%;
    height: 100%;
    background-size: cover;
    display: flex;
    cursor: pointer;
}

.img img {
    width: 100%;
    object-fit: cover;
}

/* ––––––––––––––––––––––––––– COLUMN SINGLEPAGE –––––––––––––––––––– */

.oneColumn {
    margin-bottom: 40px;
}

/* ––––––––––––––––––––––––––– MODAL –––––––––––––––––––– */


.fullScreenImage {
    max-width: 1280px;
    overflow: hidden;

}

.fullScreenImage img {
    width: 100%;
    pointer-events: none;
    user-select: none;

}

@media(max-width: 1440px) {}

@media(max-width: 1360px) {}

@media(max-width: 1280px) {}

@media(max-width: 1024px) {}

@media(max-width: 768px) {}

@media(max-width: 560px) {}

@media(max-width: 480px) {}

@media(max-width: 360px) {
    .contents .img {
        margin-bottom: 12px;
    }
}

@media(max-width: 320px) {}