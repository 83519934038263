.form {
    width: 100%;
    background-color: white;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 28px;
}

.categories {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;

}

.inputs {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.buttonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.buttonSubmut {
    background: none;
    border: none;
    cursor: pointer;
}