/*–––––––––––––––––––––––––– SIDE BAR –––––––––––––––––––––––––––––––*/
.sideBar {
    margin: 0 auto;
    max-width: 1320px;
    margin-bottom: 80px;
    font-size: 15px;
    display: flex;
    gap: 24px;
    align-items: flex-end;
    height: 100%;
    border-bottom: 1px solid var(--border);
    position: sticky;
    top: 0;
    backdrop-filter: blur(3px);

    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-padding: 0 0 0 4%;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.sideBar>* {
    width: max-content;
    display: flex;
}

.sideBar::-webkit-scrollbar {
    display: none;
}

.sideBar>* {
    /* scroll-snap-align: start; */
    /* scroll-snap-stop: always; */
}


/*–––––––––––––––––––––––––– UNIT –––––––––––––––––––––––––––––––*/


.unit {
    font-size: 15px;
    float: left;
    display: flex;
    border-bottom: 2px solid transparent;
    height: 100%;
    padding: 20px 12px 6px;
    width: max-content;
    cursor: pointer;
}

.unitActive {
    padding: 20px 12px 6px;
    border-bottom: 2px solid var(--orange);
}

.unitText {
    width: max-content;
}


@media(max-width: 1440px) {}

@media(max-width: 1360px) {}

@media(max-width: 1280px) {}

@media(max-width: 1020px) {}

@media(max-width: 768px) {}

@media(max-width: 560px) {}

@media(max-width: 480px) {
    .sideBar {
        margin-bottom: 56px;
    }
}

@media(max-width: 360px) {}

@media(max-width: 320px) {}