.main {
    background-color: var(--bg-grey);
}

.container {
    max-width: 1360px;
    margin: 0 auto 72px;
    padding: 0 20px;
}

.contents {
    width: 100%;
}

.cover {
    width: 100%;
    height: 100%;
    background-size: cover;
    display: flex;
    cursor: pointer;
}

.cover img {
    width: 100%;
    object-fit: cover;
}


@media(max-width: 1440px) {}

@media(max-width: 1360px) {}

@media(max-width: 1280px) {}

@media(max-width: 1020px) {}

@media(max-width: 768px) {}

@media(max-width: 560px) {}

@media(max-width: 480px) {}

@media(max-width: 360px) {
    .container {
        padding: 0 12px;
    }
}

@media(max-width: 320px) {}