.container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    overflow: auto;
    flex-direction: column;
    gap: 40px;

}

.content p {
    font-family: Futura;
    font-size: 20px;
    text-align: center;
}

.button {
    font-family: Futura;
    font-size: 20px;
    text-align: center;
    padding: 10px 36px;
    color: white;
    background-color: #212123;
    border-radius: 24px;
}
.img {
    width: 200px;
    overflow: hidden;
}
.img img{
    width: 100%;
}