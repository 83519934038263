.main {
    background-color: var(--bg-grey);
}

.container {
    display: flex;
    width: 1340px;
    margin: 80px auto;
    gap: 40px;
}

.form {
    margin: 0 auto;
    flex: 1 1 0;

}

.masonryContainer {
    flex: 2 1 0;
    overflow: hidden;
}

.masonry {
    width: 100%;

}

.cover {
    /* width: 100%;
    height: 100%; */
    background-size: cover;
    display: flex;
    cursor: pointer;
}

.cover :last-child {
    width: 100%;
    object-fit: cover;
}

.edit,
.delete {
    width: 16px;
    height: 16px;
    /* background: none; */
    border: none;
    cursor: pointer;
}