select,
input,
textarea {
    font-size: 15px;
    font-family: Fact, sans-serif;
    padding: 8px 16px;
    height: 40px;
    width: 100%;
    border: solid 1px var(--border-dark);
}

.hidden {
    opacity: 0;
    height: 0;
    width: 0;
    line-height: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
    position: absolute;
}


/* ––––––––––––––––CHECKBOX BUTTON ––––––––––––––––––––– */

.btnCheck {
    border: 1px solid var(--border-dark);
    padding: 10px 20px;
    font-size: 15px;
    line-height: 20px;
    width: max-content;
    cursor: pointer;
    user-select: none;
}

.btnCheck.active {
    /* border-bottom: 2px solid var(--orange); */
    background-color: var(--orange);
    border: 1px solid var(--orange);
    color: white;
}

/* ––––––––––––––––– CHECKBOX ––––––––––––––––– */

.checkbox {
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    position: relative;
}

.checkLabel {
    padding-left: 30px;
    font-size: 15px;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

.hiddenCheckbox {
    opacity: 0;
    height: 0;
    width: 0;
    line-height: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
    position: absolute;
}

.newCheckbox {
    position: absolute;
    height: 20px;
    width: 20px;
    background-image: url(/public/img/icon/Checkbox_uncheck.svg);
    margin-left: -30px;
}

.hiddenCheckbox:checked+.newCheckbox {
    background-image: url(/public/img/icon/Checkbox_check.svg)
}


/* –––––––––––––––– RADIO ––––––––––––––––––––– */


.unit {
    font-size: 15px;
    float: left;
    display: flex;
    border-bottom: 2px solid transparent;
    height: 100%;
    padding: 20px 12px 6px;
    width: max-content;
    cursor: pointer;
}

.unitActive {
    padding: 20px 12px 6px;
    border-bottom: 2px solid var(--orange);
}
.unitText {
    width: max-content;
}

/* ––––––––––––––––––– UPLOAD COVER –––––––––––––––––––––– */

.cover {
    width: 120px;
    height: 120px;
    border: 1px solid var(--border-dark);
    overflow: hidden;
    cursor: pointer;
}

.addCover {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

}

.addCover img {
    width: 100%;
    object-fit: cover;
}


/* –––––––––––––––––––––– UPLOAD FILES ––––––––––––––––––––*/


.file {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.file p {
    font-size: 15px;
}
.buttonUploadFile {
    display: flex;
    gap: 6px;
    cursor: pointer;
}

.filename {
    font-size: 13px;
    transform: translateX(32px);
    height: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
}

.name {
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;

}

.line {
    background-color: var(--black);
    width: 2px;
    height: 100%;
}
