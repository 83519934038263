.modal {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(91, 112, 131, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal {
    width: 35vw;
    background-color: white;
    overflow: hidden;
  }
  .modal img {
    width: 100%;
  }
  .modal img {
    width: 100%;
  }