.footer {
    display: flex;
    align-items: center;
    height: 44px;
    max-width: 100%;
    margin: 0 auto;
    justify-content: center;
    background-color: white;
    padding: 0 40px;
}
.logo {
    height: 40px;
    display: flex;
    align-items: center;
}