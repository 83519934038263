.main {
    background-color: var(--bg-grey);

}

.content {
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
    text-align: center;
    background-color: white;
    padding: 4%;
    justify-content: center;
}

.photo {
    background-color: white;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.photo img {
    width: 100%;
}

.skill {
    background-color: var(--bg-grey);
    width: max-content;
    padding: 8px 16px;
}

.skills {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 6px;
    margin: 20px 0;
}

.contact {
    font-weight: 500;
    display: flex;
    column-gap: 20px;
    /* justify-content: center; */
    flex-wrap: wrap;
    justify-content: center;
}

.contact a {
    color: var(--blue);
}