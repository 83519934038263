.container {
    background-color: var(--bg-grey);
}

.header {
    display: flex;
    align-items: center;
    height: 52px;
    justify-content: space-between;
    max-width: 1320px;
    margin: 0 auto;
    background-color: var(--black);
    padding: 0 24px;
}

.logoDesktop {
    display: block;
    height: 20px
}

.logoMobile {
    display: none;
}


.navBar {
    display: flex;
}

.navBarUnits {
    display: flex;
    gap: 40px;
    color: white;
    font-size: 15px;
}

.navBarUnit {
    display: flex;
    gap: 6px;
    align-items: center;
}

.portfolioIcon {
    background-size: cover;
    width: 30px;
    height: 30px;
}

.portfolioIcon img {
    width: 100%;
}

.me {
    background-size: cover;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.me img {
    width: 100%;
}


/* .shopIcon {
    background-image: url(/public/img/image/shop.png);
    background-size: cover;
    width: 30px;
    height: 30px;
} */


/* --------------------------------------------------- */
/* --------------------------------------------------- */


.burgerButton {
    display: none;
    z-index: 50;
    transition: .5s;
    transition-timing-function: ease-out;
}

.burgerButton.active {
    transform: rotate(90deg);
    transition: .5s;
    transition-timing-function: ease-out;
}

.burgerNav {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 20;
    pointer-events: none;
}

.burgerNav .active {
    pointer-events: all;
}

.burgerNavLinks {
    background-color: white;
    width: 75%;
    height: 100%;
    float: right;

    transform: translateX(100%);
    transition-timing-function: ease-in;
    transition: .25s;
    z-index: 5;
}

.burgerNavLinks.active {
    transform: translateX(0%);
    transition-timing-function: ease-out;
    transition: .4s;
    z-index: 5;
}

.backgroundBurgerNav {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 0;
    top: 0;
    left: 0;
    transition: .5s;
    z-index: -5;
}

.backgroundBurgerNav.active {
    opacity: 40%;
    transition: .5s;
    z-index: -5;
}




@media(max-width: 1440px) {}

@media(max-width: 1360px) {}

@media(max-width: 1280px) {}

@media(max-width: 1024px) {}

@media(max-width: 768px) {
    /* .navBarUnits {
        display: none;
    }

    .burgerButton {
        display: block;
    } */
}

@media(max-width: 560px) {}

@media(max-width: 480px) {}

@media(max-width: 360px) {
    .logoMobile {
        display: block;
    }
    .logoDesktop {
        display: none;
    }
}

@media(max-width: 320px) {}