.sliderContainer {
    z-index: 999;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .85);
    display: flex;
    align-items: center;
    justify-content: center;
}

.sliderContainer .cancel {
    position: fixed;
    cursor: pointer;
}

.buttonsSlider {
    position: fixed;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 300px;
    align-items: center;
}

.buttonsSlider>* {
    display: flex;
    flex: 1 1 0;
    align-items: center;
}
.prew {
    width: 100%;
    height: 100%;
    user-select: none;
}
.next {
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    user-select: none;
}
.cancel {
    top: 12px;
    right: 12px;
    user-select: none;

}