@font-face {
  font-family: Fact;
  src: url('/public/webFonts/FactSemiExpanded/font.woff2') format('woff2'), url('/public/webFonts/FactSemiExpanded/font.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: Fact;
  src: url('/public/webFonts/FactSemiExpandedBold/font.woff2') format('woff2'), url('/public/webFonts/FactSemiExpandedBold/font.woff') format('woff');
  font-weight: 700;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

html {
  height: 100%;
}

main {
  flex-grow: 1;
}

footer {
  flex-shrink: 0;
}

body {
  font-size: 16px;
  line-height: 1.5;
  font-family: Fact, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: var(--black);

  --black: #242424;
  --blue: #1473E6;
  --bg-grey: rgb(232, 232, 232);
  --border: #DDDDDD;
  --orange: #FF5502;
  --border-dark: #B3B3B3;


}

h2 {
  font-family: Fact;
  font-size: 28px;
  line-height: 1.1;
  font-weight: 700;
}

h6 {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: .75px;
  font-weight: 700;
}

a,
input,
button {
  text-decoration: none;
  outline: none;
  color: inherit;
}


@media(max-width: 1360px) {}

@media(max-width: 1280px) {}

@media(max-width: 1024px) {}

@media(max-width: 768px) {}

@media(max-width: 560px) {}

@media(max-width: 480px) {}

@media(max-width: 360px) {}

@media(max-width: 320px) {}